import React, { Component } from 'react'
import Layout from 'Components/Layout'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import { ERROR, SUCCESS, LOADING } from 'Components/UI/Notifications'
import BackgroundBtn from 'Components/UI/BackgroundBtn'
import Form from 'Components/UI/Form'
import { Wrapper } from 'Components/MyAccount/SubPage'
import assign from 'object-assign'
import styles from './index.module.scss'

class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
    this.state = {
      isSuccessfully: false
    }
  }
  componentDidMount() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentDidUpdate() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  onSubmit = () => {
    const email = this.form.current.refs.forgotMail.state.value
    setTimeout(AppActions.openNotification, 0, { type: LOADING, message: 'We are processing your request...', stayOpen: true })
    fetch(`${AppConstants.API_URL}reset-account-password`, assign(AppConstants.POST_OPTIONS, { body: JSON.stringify({ email }) }))
    .then((res) => res.json())
    .then((data) => {
      const error = data.type && data.type === 'error' ? true : false
      if (error) {
        setTimeout(AppActions.openNotification, 0, { type: ERROR, message: data.message })
        this.setState({isSuccessfully: false})
      }
      else {
        setTimeout(AppActions.openNotification, 0, { type: SUCCESS, message: data.message })
        this.setState({isSuccessfully: true})
      }
    })
    .catch(() => {
      setTimeout(AppActions.openNotification, 0, { type: ERROR, message: 'Something went wrong, try again' })
      this.setState({isSuccessfully: false})
    })
  }
  render() {
    const form = [
      {
        type: 'input',
        props: {
          id: 'forgotMail',
          className: 'u-col-lg-4',
          type: 'email',
          placeholder: 'Email Address',
          required: true
        }
      }
    ]
    return (
      <Layout>
        <Wrapper className={`${styles.parent}`}>
          <section className="u-col-lg-10 u-offset-lg-1">
            <div className='u-height--small' />
            <p className={`t-color-black t-title-2 u-uppercase`}>Forgotten Password</p>
            <div className='u-height--20'/>
            <p className={`t-paragraph-0`}>Please enter the e-mail address you registered with, and we will send you a link to reset your password.</p>
            <div className='u-height--20'/>
            <Form className={`u-remove-autofill`} items={form} submit={this.onSubmit} ref={this.form}>
              <div className="u-pad-t--40">
                <BackgroundBtn
                  className="u-col-lg-3"
                  text="Submit"
                  type="submit"
                  fullBlack
                />
              </div>
            </Form>
          </section>
        </Wrapper>
      </Layout>
    )
  }
}

export default ForgotPassword
