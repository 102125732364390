import React, { Component } from 'react'
import { navigate } from 'gatsby'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import AppStore from 'AppStore'

export const Wrapper = (props) => {
  return (
    <div className={`${props.className} u-row`}>
      <div className='u-height--lg--60' />
      <div className={`u-height--only-lg--20`}></div>
      {props.children}
      <div className={`u-height--only-lg--40`}></div>
      <div className='u-height--lg--60' />
    </div>
  )
}

class SubPage extends Component {
  constructor(props) {
    super(props)
    const customer = AppStore.getCustomer()
    if (typeof window !== `undefined`) {
      if (!customer || !customer.id) navigate('/my-account/login/')
    }
  }
  componentDidMount() {
    const customer = AppStore.getCustomer()
    if (!customer || !customer.id) navigate('/my-account/login/')
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
    AppStore.on(AppConstants.LOGOUT, this.onLogout)
  }
  componentWillUnmount() {
    AppStore.off(AppConstants.LOGOUT, this.onLogout)
  }
  componentDidUpdate() {
    const customer = AppStore.getCustomer()
    if (!customer || !customer.id) navigate('/my-account/login/')
  }
  onLogout = () => {
    navigate('/my-account/login/')
  }
}

export default SubPage
